export class LoadOrganizations {
  static readonly type = '[Organization] LoadOrganizations';
}

export class LoadActiveOrganization {
  static readonly type = '[Organization] LoadActiveOrganization';
}

export class LoadUsersForOrganizationId {
  static readonly type = '[Organization] LoadUsersForOrganizationId';
  constructor(public organizationId: string) {}
}

export class SetActiveOrganization {
  static readonly type = '[Organization] SetActiveOrganization';
  constructor(public organizationId: string | null) {}
}
