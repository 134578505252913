import {
  Action,
  createSelector,
  Selector,
  State,
  StateContext,
} from '@ngxs/store';
import { inject, Injectable } from '@angular/core';
import { Organization, OrganizationStateModel } from './organization.model';
import { catchError, finalize, map, of, tap } from 'rxjs';
import { OrganizationsService } from '../../services/organizations.service';
import {
  LoadActiveOrganization,
  LoadOrganizations,
  LoadUsersForOrganizationId,
  SetActiveOrganization,
} from './organization.action';
import {
  SetOrganizationsLoading,
  SetOrganizationUsersLoading,
} from '../loading/loading.action';
import {
  LocalStorageService,
  SfaLocalStorageKey,
} from '../../services/local-storage.service';
import { User } from '../user/user.model';
import { Auth } from '@angular/fire/auth';
import { SetUseRoleForActiveOrganization } from '../user/user.action';
import { BadgeService } from '../../../shared/components/badge/badge.service';
import {
  BadgePurpose,
  BadgeStatus,
} from '../../../shared/components/badge/badge.models';
import { StripeProductId } from '../../../shared/models/models';

@State<OrganizationStateModel>({
  name: 'organizationState',
  defaults: {
    organizations: null,
    activeOrganizationId: null,
  },
})
@Injectable()
export class OrganizationState {
  private organizationsService = inject(OrganizationsService);
  private localStorageService = inject(LocalStorageService);
  private badgeService = inject(BadgeService);
  private auth = inject(Auth);

  @Selector()
  static organizations(state: OrganizationStateModel) {
    return state.organizations;
  }

  @Selector()
  static activeOrganizationId(state: OrganizationStateModel) {
    return state.activeOrganizationId;
  }

  @Selector()
  static activeOrganization(state: OrganizationStateModel) {
    return state.organizations?.find(
      (organization) => organization.id === state.activeOrganizationId,
    )!;
  }

  /**
   * This is a dynamic selector - used to provide parameterised selections
   * (https://www.ngxs.io/concepts/select#dynamic-selectors)
   * In this case, it selects an organization by the organizationId
   */
  static organizationById(id: string) {
    return createSelector(
      [OrganizationState],
      (state: OrganizationStateModel) =>
        state.organizations?.find((organization) => organization.id === id),
    );
  }

  static usersForOrganizationById(id: string) {
    return createSelector(
      [OrganizationState],
      (state: OrganizationStateModel) =>
        state.organizations?.find((organization) => organization.id === id)
          ?.users,
    );
  }

  static userRoleForOrganizationById(id: string) {
    const auth = inject(Auth);
    return createSelector(
      [OrganizationState],
      (state: OrganizationStateModel) =>
        state.organizations
          ?.find((organization) => organization.id === id)
          ?.users.find((user) => user.id === auth.currentUser?.uid)?.role,
    );
  }

  @Action(LoadOrganizations)
  loadOrganizations(ctx: StateContext<OrganizationStateModel>) {
    ctx.dispatch(new SetOrganizationsLoading(true));
    return this.organizationsService.getOrganizations().pipe(
      map(this._enrichFrontendData),
      tap((organizations: Organization[]) => {
        ctx.patchState({ organizations });

        this.badgeService.setStatus({
          purpose: BadgePurpose.NO_ORGANIZATION,
          status: !!organizations.length
            ? BadgeStatus.INACTIVE
            : BadgeStatus.INFO,
        });

        this.badgeService.setStatus({
          purpose: BadgePurpose.ORGANIZATION_HAS_USER_REQUESTS,
          status: !organizations.find((organization) => organization.showBadge)
            ? BadgeStatus.INACTIVE
            : BadgeStatus.INFO,
        });
      }),
      catchError((error) => {
        console.error('Error loading organizations', error);
        return of([]);
      }),
      finalize(() =>
        ctx.dispatch([
          new LoadActiveOrganization(),
          new SetOrganizationsLoading(false),
        ]),
      ),
    );
  }

  @Action(LoadActiveOrganization)
  LoadActiveOrganization(ctx: StateContext<OrganizationStateModel>) {
    const state = ctx.getState();
    let localStorageActiveOrganization = this.localStorageService.getItem(
      SfaLocalStorageKey.ACTIVE_ORGANIZATION_ID,
    );

    // Remove the localStorage key, if the active organization is not found in organizations
    if (
      localStorageActiveOrganization &&
      !state.organizations?.find(
        (organization) => organization.id == localStorageActiveOrganization,
      )
    ) {
      localStorageActiveOrganization = null;
      this.localStorageService.removeItem(
        SfaLocalStorageKey.ACTIVE_ORGANIZATION_ID,
      );
    }

    ctx.dispatch(
      new SetActiveOrganization(
        localStorageActiveOrganization || state.organizations?.[0]?.id || null,
      ),
    );
  }

  @Action(LoadUsersForOrganizationId)
  LoadUsersForOrganizationId(
    ctx: StateContext<OrganizationStateModel>,
    action: LoadUsersForOrganizationId,
  ) {
    ctx.dispatch(new SetOrganizationUsersLoading(true));
    return this.organizationsService
      .getUsersForOrganization(action.organizationId)
      .pipe(
        tap((activeOrganizationUsers: User[]) => {
          const state = ctx.getState();

          // Update the organization with the new users
          const updatedOrganizations = state.organizations?.map((org) => {
            if (org.id === action.organizationId) {
              return {
                ...org,
                users: activeOrganizationUsers,
              };
            }
            return org;
          });

          ctx.patchState({
            organizations: updatedOrganizations,
          });
        }),
        catchError((error) => {
          console.error('Error loading users for active organization', error);
          return of([]);
        }),
        finalize(() => ctx.dispatch(new SetOrganizationUsersLoading(false))),
      );
  }

  @Action(SetActiveOrganization)
  SetActiveOrganization(
    ctx: StateContext<OrganizationStateModel>,
    action: SetActiveOrganization,
  ) {
    const state = ctx.getState();

    ctx.patchState({
      activeOrganizationId: action.organizationId,
    });
    this.localStorageService.setItem(
      SfaLocalStorageKey.ACTIVE_ORGANIZATION_ID,
      action.organizationId,
    );

    // Now set the role of the user for the active organization
    const userRoleForActiveOrganization = state.organizations
      ?.find((organization) => organization.id === action.organizationId)
      ?.users.find((user) => user.id === this.auth.currentUser?.uid)?.role;

    if (userRoleForActiveOrganization) {
      ctx.dispatch(
        new SetUseRoleForActiveOrganization(userRoleForActiveOrganization),
      );
    }
  }

  /**
   * This will calculate the current product, e.g. if the user is in Testphase
   */
  private _enrichFrontendData = (
    organizations: Organization[],
  ): Organization[] => {
    return organizations.map((organization) => {
      // If needed, set deactivated
      if (
        !organization.stripeReadableProductId ||
        organization.stripeSubscriptionStatus !== 'active'
      ) {
        organization.stripeReadableProductId = StripeProductId.DEACTIVATED;
      }

      // Calculate if still in testphase
      if (
        organization.stripeReadableProductId === StripeProductId.DEACTIVATED
      ) {
        const createdAtDate = new Date(organization.createdAt._seconds * 1000);
        const timeDifference = Date.now() - createdAtDate.getTime();
        const daysDifference = Math.floor(
          timeDifference / (1000 * 60 * 60 * 24),
        );
        const daysLeftInTestphase = 60 - daysDifference;

        if (daysLeftInTestphase > 0) {
          organization.daysLeftInTestphase = daysLeftInTestphase;
          organization.stripeReadableProductId = StripeProductId.TESTPHASE;
        }
      }

      // Calculate granting level
      switch (organization.stripeReadableProductId) {
        case StripeProductId.BASIC:
          organization.grantingLevel = 1;
          break;
        case StripeProductId.STANDARD:
          organization.grantingLevel = 2;
          break;
        case StripeProductId.TESTPHASE:
        case StripeProductId.PREMIUM:
          organization.grantingLevel = 3;
          break;
        default: // (StripeProductId.DEACTIVATED)
          organization.grantingLevel = 0;
      }

      return organization; // Return the modified organization object
    });
  };
}
