import {
  inject,
  Injectable,
  PLATFORM_ID,
  Signal,
  signal,
  WritableSignal,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '../../../environments/environment';

export enum SfaLocalStorageKey {
  ACTIVE_ORGANIZATION_ID = 'schriftfuehrerActiveOrganizationId',
}

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private isBrowser = isPlatformBrowser(inject(PLATFORM_ID));

  setItem(key: SfaLocalStorageKey, value: string | null) {
    this.isBrowser &&
      value &&
      localStorage.setItem(this._mapDevProdSfaLocalStorageKey(key), value);
  }

  getItem(key: SfaLocalStorageKey) {
    return this.isBrowser
      ? localStorage.getItem(this._mapDevProdSfaLocalStorageKey(key))
      : null;
  }

  removeItem(key: SfaLocalStorageKey) {
    this.isBrowser &&
      localStorage.removeItem(this._mapDevProdSfaLocalStorageKey(key));
  }

  /**
   * This adds Dev to the key, if it's not prod environment
   * @param key
   * @private
   */
  private _mapDevProdSfaLocalStorageKey(key: SfaLocalStorageKey): string {
    return `${key}${!environment.production ? 'Dev' : ''}`;
  }
}
