import { inject, Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { deleteDoc, doc, Firestore, setDoc } from '@angular/fire/firestore';
import { Organization } from '../store/organization/organization.model';

@Injectable({
  providedIn: 'root',
})
export class OrganizationsService {
  private http = inject(HttpClient);
  private firestore = inject(Firestore);

  getOrganizations(): Observable<Organization[]> {
    return this.http.get<Organization[]>(
      `${environment.domains.functionStripe}/organizations`,
    );
  }

  deleteOrganization(id: string): Observable<any> {
    return this.http.delete<any>(
      `${environment.domains.functionStripe}/organizations/delete/${id}`,
    );
  }

  getUsersForOrganization(id: string) {
    return this.http.get<any>(
      `${environment.domains.functionStripe}/organizations/${id}/users`,
    );
  }

  acceptUserRequestForOrganization(organizationId: string, userId: string) {
    const docRef = doc(
      this.firestore,
      `organizations/${organizationId}/users/${userId}`,
    );
    return from(
      setDoc(docRef, {
        id: userId,
        role: 'user',
      }),
    );
  }

  deleteUserRequestForOrganization(organizationId: string, userId: string) {
    return from(
      deleteDoc(
        doc(
          this.firestore,
          `organizations/${organizationId}/usersRequestedAccess/${userId}`,
        ),
      ),
    );
  }
}
